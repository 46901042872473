<template>
  <KTCard :page="page">
    <template #body>
      <KTTable
        :fields="mao_de_obra_columns"
        :items="mao_de_obra.data"
        :primaryKey="mao_de_obra.primaryKey"
        :sortBy="mao_de_obra.sortBy"
        :paginated="false"
        :searchable="false"
      >
        <template #custom-foot>
          <tr class="bg-primary text-white" v-if="mao_de_obra.foot_data[0]">
            <td>Total</td>
            <td class="text-center">
              {{ mao_de_obra.foot_data[0].simbolo_moeda }}
            </td>
            <template v-for="(ano, index) in anos">
              <td :key="'td-anos-2-' + index"></td>
              <td :key="'td-anos-3-' + index" class="text-right">
                {{
                  formatCurrency(
                    mao_de_obra.foot_data[0]["valor_total_mensal_ano_" + ano]
                  )
                }}
              </td>
            </template>
          </tr>
        </template>
      </KTTable>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "PipResumoMaoDeObraList",
  props: {
    idplano_investimento: {
      required: true
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-handshake",
        title: "Resumo da Mão de Obra",
        description: `Aqui você pode verificar de forma resumida todos os gastos
          com mão de obra. Aproveite para revisar se está tudo correto e se for o
          caso, retorne às etapas anteriores para realizar os ajustes necessários.`
      },
      mao_de_obra: {
        data: [],
        foot_data: [],
        primaryKey: "tipo",
        sortBy: "descricao_tipo"
      },
      anos: 0,
      tipo_plano_investimento: null
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/resumo_mao_de_obra`;
    },
    manual() {
      return {
        session: "resumo_mao_de_obra",
        tipo: this.tipo_plano_investimento
      };
    },
    parentRoute() {
      return {
        name: "pip_ver",
        params: { idplano_investimento: this.idplano_investimento }
      };
    },
    mao_de_obra_columns() {
      let columns = [
        {
          key: "descricao_tipo",
          label: "Tipo",
          sortable: true
        },
        {
          key: "simbolo_moeda",
          label: "Moeda",
          sortable: true,
          tdClass: "text-center"
        }
      ];

      for (let ano = 1; ano <= this.anos; ano++) {
        columns.push({
          key: "quantidade_funcionarios_ano_" + ano,
          label: "Quantidade Funcionários Ano " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "number"
        });
        columns.push({
          key: "valor_total_mensal_ano_" + ano,
          label: "Total Mensal " + ano,
          sortable: true,
          tdClass: "text-right",
          formatter: "currency"
        });
      }

      return columns;
    }
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.mao_de_obra.data = res[R_GETLIST].mao_de_obra ?? [];
        this.mao_de_obra.foot_data = res[R_GETLIST].mao_de_obra_total ?? [];
        this.anos = res[R_GETLIST].anos;
        this.tipo_plano_investimento = res[R_GETLIST].tipo_plano_investimento;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
